<style scope>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="getData" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>系统设置</el-breadcrumb-item>
			  <el-breadcrumb-item>添加管理员</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="background-color: white; margin: 10px; padding: 30px 0; border-radius: 10px;">
			
			<div class="line-box">
				<div class="line-name">账号：</div>
				<el-input style="width: 720px;" placeholder="请输入账号" v-model="userInfo.username"></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">昵称：</div>
				<el-input style="width: 720px;" placeholder="请输入昵称" v-model="userInfo.nickname"></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">密码：</div>
				<el-input style="width: 720px;" placeholder="请输入密码" v-model="userInfo.password"></el-input>
			</div>
			
			<div class="line-box">
				<div class="line-name"></div>
				<el-button style="width: 720px;" type="danger" @click="addProduct">确认添加</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				userInfo:{},
				editStatus : false
			}
		},
		methods: {
			addProduct() {
				// 验证格式
				if(this.userInfo.username == ""){
					return this.$message({ message: '请输入账号', type: 'warning' });
				}
				if(this.userInfo.password == ""){
					return this.$message({ message: '请填写密码', type: 'warning' });
				}
				if(this.userInfo.username.length < 6){
					return this.$message({ message: '账号长度需要>=6位', type: 'warning' });
				}
				if(this.userInfo.password.length < 6){
					return this.$message({ message: '密码长度需要>=6位', type: 'warning' });
				}
				if(this.userInfo.nickname == ""){
					return this.$message({ message: '请填写昵称', type: 'warning' });
				}
				// 提交保存
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Admin/addManager", this.userInfo).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.userInfo.password = '';
						_this.userInfo.username = '';
						_this.userInfo.nickname = '';
						_this.$message({
							 message: '添加成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(err=>{
					loading.close();
				});
			},
			beforeUpload(file){
			},
			UploadSuccess(res, file, fileList){
				if(res.code == 200)
				{
					this.userInfo.avatar_url = res.file_path;
					this.userInfo.avatar_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			}
		},
	}
</script>

<style>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 120px;
	    height: 120px;
	    line-height: 120px;
	    text-align: center;
	  }
	  .avatar {
	    width: 120px;
	    height: 120px;
	    display: block;
	  }
	  
	  .avatar-uploader-icon1 {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar1 {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>

		<div class="upload-box">
			<div class="line-name">上传二维码：</div>
			<div style="display: block;">
				<el-upload class="avatar-uploader" :action="this.$http_url + '/Upload/UploadImage'" :headers="headers" :show-file-list="false" 
					:on-success="UploadSuccess"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="paywayInfo.qrcode_fullurl" :src="paywayInfo.qrcode_fullurl" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
		</div>
		<div class="line-box">
			<div class="line-name">选择币种：</div>
			<el-select style="width: 720px;" v-model="paywayInfo.inst" placeholder="请选择币种">
			    <el-option v-for="item in digit_list" :key="item.value" :label="item.label" :value="item.value"></el-option>
			  </el-select>
		</div>
		<div class="upload-box">
			<div class="line-name">上传图标：</div>
			<div style="display: block;">
				<el-upload class="avatar-uploader" :action="this.$http_url + '/Upload/UploadImage'" :headers="headers" :show-file-list="false" 
					:on-success="UploadSuccess1"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="paywayInfo.icon_fullurl" :src="paywayInfo.icon_fullurl" class="avatar1">
				  <i v-else class="el-icon-plus avatar-uploader-icon1"></i>
				</el-upload>
			</div>
		</div>
		<div class="line-box" v-show="paywayInfo.inst == 'USDT'">
			<div class="line-name">链类型：</div>
			<el-select style="width: 720px;" v-model="paywayInfo.chain" placeholder="请选择链类型">
			    <el-option v-for="item in chain_list" :key="item.value" :label="item.label" :value="item.value"></el-option>
			  </el-select>
		</div>
		
		<div class="line-box">
			<div class="line-name">钱包地址：</div>
			<el-input style="width: 720px;" placeholder="请填写钱包地址" v-model="paywayInfo.address"></el-input>
		</div>
		
		
		<div class="line-box">
			<div class="line-name">充值范围：</div>
			<el-input type="number" placeholder="最小充值额" v-model="paywayInfo.limit_low" style="width: 360px;">
				<template slot="prepend">最小值</template>
			</el-input>
			<el-input type="number" placeholder="最大充值额" v-model="paywayInfo.limit_high" style="width: 360px;">
				<template slot="prepend">最大值</template>
			</el-input>
		</div>
		
		<div style="width: 100%; margin-top: 20px;">
			<el-button style="width: 100%;" type="danger" @click="addProduct">确认修改</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props:['paywayInfo'],
		data(){
			return {
				headers:{
					Authorization:''
				},
				digit_list:[
					{
						lable:'USDT',
						value:'USDT'
					},{
						lable:'BTC',
						value:'BTC'
					},{
						lable:'ETH',
						value:'ETH'
					}
				],
				chain_list:[
					{
						label:'ERC20',
						value:'ERC20'
					},{
						label:'TRC20',
						value:'TRC20'
					}
				]
			}
		},
		created() {
			this.headers.Authorization = localStorage.getItem('access_token');
		},
		methods: {
			addProduct() {
				console.log("add product", this.paywayInfo);
				// 验证格式
				if(this.paywayInfo.qrcode_url == ""){
					return this.$message({ message: '请上传二维码', type: 'warning' });
				}
				if(this.paywayInfo.inst == ""){
					return this.$message({ message: '请选择币种', type: 'warning' });
				}
				if(this.paywayInfo.icon_url == ""){
					return this.$message({ message: '请上传图标', type: 'warning' });
				}
				if(this.paywayInfo.inst=='USDT' && this.paywayInfo.chain == ""){
					return this.$message({ message: '请选择链类型', type: 'warning' });
				}
				if(this.paywayInfo.address == ""){
					return this.$message({ message: '请填写钱包地址', type: 'warning' });
				}
				if(this.paywayInfo.limit_high == "" || this.paywayInfo.limit_low == ""){
					return this.$message({ message: '请填写充值范围', type: 'warning' });
				}
				if(Number(this.paywayInfo.limit_low) > Number(this.paywayInfo.limit_high) ){
					return this.$message({ message: '充值范围,最小值必须小于最大值', type: 'warning' });
				}
				// 提交上传
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '编辑中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Recharge/editPayway", this.paywayInfo).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '编辑成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			beforeUpload(file){
			},
			UploadSuccess(res, file, fileList){
				if(res.code == 200)
				{
					this.paywayInfo.qrcode_url = res.file_path;
					this.paywayInfo.qrcode_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadSuccess1(res, file, fileList){
				if(res.code == 200)
				{
					this.paywayInfo.icon_url = res.file_path;
					this.paywayInfo.icon_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			}
		},
	}
</script>

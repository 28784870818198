<style scoped>
	
	
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		margin-left: 30px;
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
		margin-left: 30px;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div class="content">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="getData" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>财务管理</el-breadcrumb-item>
			  <el-breadcrumb-item>手动充值</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="background-color: white; margin: 10px; padding: 30px 0; border-radius: 10px;">
		<div class="line-box">
			<div class="line-name">用户UID：</div>
			<el-input type="number" style="width: 720px;" v-model="userinfo.uid" @blur="searchUser" placeholder="用户UID" ></el-input>
		</div>
		
		<div class="line-box">
			<div class="line-name">用户昵称：</div>
			<el-input style="width: 720px;" v-model="userinfo.nickname" disabled placeholder="用户昵称" ></el-input>
		</div>
		
		<div class="line-box">
			<div class="line-name">当前余额：</div>
			<el-input type="number" style="width: 720px;" disabled v-model="userinfo.balance" placeholder="当前余额"></el-input>
		</div>
		
		<div class="line-box">
			<div class="line-name">操作余额：</div>
			<el-input type="number" style="width: 720px;" v-model="change" placeholder="操作余额100 -100"></el-input>
		</div>
		
		
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">说明： 增加多少余额填多少，扣减多少余额填负多少。如加100（填100），减100（填-100）</div>
		</div>
		
		
		<div class="line-box">
			<div class="line-name">缘由：</div>
			<el-input style="width: 720px; " placeholder="" v-model="reason"></el-input>
		</div>
		
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">说明： 显示在帐变记录的缘由中</div>
		</div>
		
		<div style="width: 100%; margin-top: 20px; margin-left: 30px;">
			<el-button style="width: 860px;" type="danger" @click="submit">确认充值</el-button>
		</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				userinfo:{
					uid:'',
					nickname:'',
					balance:'',
				},
				change:'',
				reason:'',
			}
		},
		created(){
		},
		methods: {
			searchUser(){
				let _this = this;
				if(_this.userinfo.uid == '' || _this.userinfo.uid == 0){
					return;
				}
				const loading = this.$loading({
						lock: true,
						text: '加载中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Recharge/getUserBalance", {uid:_this.userinfo.uid}).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.userinfo = res.data;
					}else{
						_this.userinfo.uid = '';
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '处理中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Recharge/HandPayApi", {uid:_this.userinfo.uid, reason:_this.reason, change:_this.change}).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '操作成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(err=>{
					loading.close();
				});
			},
		},
	}
</script>

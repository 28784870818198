import { render, staticRenderFns } from "./DrawList.vue?vue&type=template&id=2d4b9613&scoped=true&"
import script from "./DrawList.vue?vue&type=script&lang=js&"
export * from "./DrawList.vue?vue&type=script&lang=js&"
import style0 from "./DrawList.vue?vue&type=style&index=0&id=2d4b9613&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d4b9613",
  null
  
)

export default component.exports
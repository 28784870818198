<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>订单管理</el-breadcrumb-item>
			  <el-breadcrumb-item>平仓日志</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" :model="searchForm" class="demo-form-inline">
					<el-form-item label="">
						<el-input v-model="searchForm.keyword" placeholder="Orid、Uid"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" border  style="width: 100%"
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)" >
					
					<el-table-column prop="orid" label="订单编号" width="100"  align="center">
					</el-table-column>
					
					<el-table-column prop="uid" label="用户UID" width="100"  align="center">
					</el-table-column>
					
					<el-table-column prop="add_time" label="订单时间" align="center" width="170">
						<template slot-scope="scope">
							{{ formatTimestamp(scope.row.add_time) }}
						</template>
					</el-table-column>
					
					<el-table-column prop="inst" label="产品信息" width="200"  align="center">
					</el-table-column>
					
					<el-table-column prop="status" label="状态" width="100"  align="center">
					</el-table-column>
					
					<el-table-column prop="direction" label="方向" width="100"  align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.direction=='up'" style="color:red;">买涨</span>
							<span v-if="scope.row.direction=='down'" style="color:green;">买跌</span>
						</template>
					</el-table-column>
					
					<el-table-column prop="time" label="时间/点数" align="center" width="180">
						<template slot-scope="scope">
							<span>{{scope.row.time}}S / {{scope.row.rate}}%</span>
						</template>
					</el-table-column>
					
					<el-table-column prop="cur_price" label="建仓点位" align="center">
					</el-table-column>
					
					<el-table-column prop="calc_price" label="平仓点位" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.profit>0" style="color:red;">{{scope.row.calc_price}}</span>
							<span v-if="scope.row.profit<0" style="color:green;">{{scope.row.calc_price}}</span>
						</template>
					</el-table-column>
					
					<el-table-column prop="amount" label="委托金额" align="center">
					</el-table-column>
					
					<el-table-column prop="profit" label="实际盈亏" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.profit>0" style="color:red;">{{scope.row.profit}}</span>
							<span v-if="scope.row.profit<0" style="color:green;">{{scope.row.profit}}</span>
						</template>
					</el-table-column>
					
					<el-table-column fixed="right" label="操作" width="220" align="center">
						<template slot-scope="scope">
							<el-button @click="EditUser(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
	</div>
</template>

<script>
	
	export default {
		components:{
		},
		name:'List',
		data(){
			return {
				balance_uid:0,
				userInfo:{},
				balanceView:false,
				editView:false,
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				searchForm:{
					status:"1",
					keyword:""
				},
				tableData: []
			}
		},
		created() {
			this.getData(1);
		},
		methods:{
			ViewBalance(_uid){
				this.balance_uid = _uid;
				this.balanceView = true;
			},
			changeAgent(scope){
				let _this = this;
				let user = scope.row;
				let _is_agent = user.is_agent == 0 ? 1 : 0;
				_this.$api.post("/User/editAgent", {uid:user.uid, is_agent:_is_agent}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].is_agent = _is_agent;
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			changeStatus(scope){
				let _this = this;
				let user = scope.row;
				let _status = user.status == 0 ? 1 : 0;
				_this.$api.post("/User/editStatus", {uid:user.uid, status:_status}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].status = _status;
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			EditUser(row){
				this.userInfo = row;
				this.userInfo.password = '';
				this.editView = true;
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
			refresh(){
				this.getData(1);
				this.tableKey +=1;
			},
			getData(page){
				let that = this;
				that.loading = true;
				that.$api.post("/Order/OrderList", {...this.searchForm, page:page}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleClick(e){
			},
			onSubmit(){
				this.getData(1);
			}
		}
	}
</script>
<style scope>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>
		<div class="line-box">
			<div class="line-name">AID：</div>
			<el-input style="width: 720px;" placeholder="" disabled v-model="userInfo.aid"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">账号：</div>
			<el-input style="width: 720px;" placeholder="" v-model="userInfo.username"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">昵称：</div>
			<el-input style="width: 720px;" placeholder="" v-model="userInfo.nickname"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">密码：</div>
			<el-input style="width: 720px;" placeholder="重置密码" v-model="userInfo.password"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 重置修改密码的时候才填写，不修改留空</div>
		</div>
		
		
		<div style="width: 100%; margin-top: 20px;">
			<el-button style="width: 100%;" type="danger" @click="addProduct">确认修改</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props:['userInfo'],
		data(){
			return {
				editStatus : false
			}
		},
		data(){
			return {
				headers:{
					Authorization:''
				}
			}
		},
		methods: {
			addProduct() {
				// 验证格式
				if(this.userInfo.nickname == ""){
					return this.$message({ message: '请填写昵称', type: 'warning' });
				}
				if(this.userInfo.username == ""){
					return this.$message({ message: '请填写账号', type: 'warning' });
				}
				if(this.userInfo.password == "" && this.userInfo.password.length<6 ){
					return this.$message({ message: '密码需要>=6位', type: 'warning' });
				}
				// 提交保存
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '修改中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Admin/editManager", this.userInfo).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '编辑成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(err=>{
					loading.close();
				});
			},
		},
	}
</script>

<style>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>
		<div class="upload-box">
			<div class="line-name">上传图标：</div>
			<div style="display: block;">
				<el-upload class="avatar-uploader" :action="this.$http_url + '/Upload/UploadImage'" :headers="headers" :show-file-list="false" 
					:on-success="UploadSuccess"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="form.icon_fullurl" :src="form.icon_fullurl" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			
		</div>
		<div class="line-box">
			<div class="line-name">产品名称：</div>
			<el-input style="width: 720px;" placeholder="请填写产品名称,如 BTC/USDT" v-model="form.product_name"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">产品代码：</div>
			<el-input style="width: 720px;" placeholder="请填写产品代码,如 BTC-USDT" v-model="form.inst"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">风控波动范围：</div>
			<el-input type="number" placeholder="风控波动最小值" v-model="form.risk_low" style="width: 360px;">
				<template slot="prepend">最小值</template>
			</el-input>
			<el-input type="number" placeholder="风控波动最大值" v-model="form.risk_high" style="width: 360px;">
				<template slot="prepend">最大值</template>
			</el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 客户订单在条件范围内时，会根据订单的涨或跌，自动减或加最小值与最大值之间的随机数，留空或者0则为不开启</div>
		</div>
		<div class="line-box">
			<div class="line-name">随机波动范围：</div>
			<el-input type="number" style="width: 720px;" placeholder="请填写随机波动范围" v-model="form.rand_price"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 产品获取接口值后，会加上+-此处的值。如5，则在接口获取的数据中加上-5~5之间的随机数。</div>
		</div>
		
		<div class="line-box">
			<div class="line-name">时间玩法间隔：</div>
			<el-input style="width: 720px;" placeholder="请填写时间玩法间隔" v-model="form.time_str"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 如时间为：60秒、120秒、300秒，则请用字母逗号将时间分开，如输入：60,120,300。如没有此玩法则留空。必须为三个</div>
		</div>
		<div class="line-box">
			<div class="line-name">盈亏比例：</div>
			<el-input style="width: 720px;" placeholder="请填写盈亏比例" v-model="form.scale_str"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 如比例为：75%、77%，80%，则请用字母逗号将比例分开，如输入：75,77,80。必须为3个，且不得为空</div>
		</div>
		
		<div class="line-box">
			<div class="line-name">最低投注额间隔：</div>
			<el-input style="width: 720px;" placeholder="请填写最低投注额间隔" v-model="form.limit_str"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 如金额为：10、50，100，则请用字母逗号将比例分开，如输入：10,50,100。必须为3个，且不得为空</div>
		</div>
		<div class="line-box">
			<div class="line-name">最高投注额间隔：</div>
			<el-input style="width: 720px;" placeholder="请填写最高投注额间隔" v-model="form.limits_str"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 如金额为：10、50，100，则请用字母逗号将比例分开，如输入：10,50,100。必须为3个，且不得为空</div>
		</div>
		
		<div style="width: 100%; margin-top: 20px;">
			<el-button style="width: 100%;" type="primary" @click="addProduct">确认添加</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				form:{
					icon_url:'',
					icon_fullurl:'',
					product_name:'',
					inst:'',
					risk_low:'',
					risk_high:'',
					rand_price:'',
					time_str:'',
					scale_str:'',
					limit_str:'',
					limits_str:''
				},
				headers:{
					Authorization:''
				}
			}
		},
		created() {
			this.headers.Authorization = localStorage.getItem('access_token');
		},
		methods: {
			addProduct() {
				console.log("add product", this.form);
				// 验证格式
				if(this.form.icon_url == ""){
					return this.$message({ message: '请上传图标', type: 'warning' });
				}
				if(this.form.product_name == ""){
					return this.$message({ message: '请填写产品名称', type: 'warning' });
				}
				if(this.form.inst == ""){
					return this.$message({ message: '请填写产品代码', type: 'warning' });
				}
				if(this.form.risk_low == "" || this.form.risk_high == ""){
					return this.$message({ message: '请填写风险波动范围', type: 'warning' });
				}
				if(Number(this.form.risk_low) > Number(this.form.risk_high) ){
					return this.$message({ message: '风险波动范围,最小值必须小于最大值', type: 'warning' });
				}
				if(this.form.rand_price == ""){
					return this.$message({ message: '请填写随机波动范围', type: 'warning' });
				}
				if(this.form.time_str == ""){
					return this.$message({ message: '请填写时间玩法间隔', type: 'warning' });
				}
				if(this.form.scale_str == ""){
					return this.$message({ message: '请填写盈亏比例', type: 'warning' });
				}
				if(this.form.limit_str == ""){
					return this.$message({ message: '请填写最低投注额间隔', type: 'warning' });
				}
				if(this.form.limits_str == ""){
					return this.$message({ message: '请填写最高投注额间隔', type: 'warning' });
				}
				let time_arr = this.form.time_str.split(",");
				if(time_arr.length < 3){
					return this.$message({ message: '时间玩法间隔最低3个值', type: 'warning' });
				}
				let scale_arr = this.form.scale_str.split(",");
				if(scale_arr.length < 3){
					return this.$message({ message: '盈亏比例间隔最低3个值', type: 'warning' });
				}
				let limit_arr = this.form.limit_str.split(",");
				if(limit_arr.length < 3){
					return this.$message({ message: '最低投注额间隔最低3个值', type: 'warning' });
				}
				let limits_arr = this.form.limits_str.split(",");
				if(limits_arr.length < 3){
					return this.$message({ message: '最高投注额间隔最低3个值', type: 'warning' });
				}
				
				if(time_arr.length != scale_arr.length ){
					return this.$message({ message: '盈亏比个数要与时间玩法间隔个数一致~', type: 'warning' });
				}
				if(scale_arr.length != limit_arr.length ){
					return this.$message({ message: '最低限额个数要与盈亏比个数一致~', type: 'warning' });
				}
				if(limit_arr.length != limits_arr.length ){
					return this.$message({ message: '最高限额个数要与最低限额个数一致~', type: 'warning' });
				}
				// 提交上传
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Product/addProduct", this.form).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '添加成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			beforeUpload(file){
			},
			UploadSuccess(res, file, fileList){
				if(res.code == 200)
				{
					this.form.icon_url = res.file_path;
					this.form.icon_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			}
		},
	}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import MainFrame from '@/components/MainFrame'
import Login from '@/components/Login'

//数据面板
import Dashboard from '@/components/Dashboard/Dashboard'

//产品管理
import ProductList from '@/components/Product/ProductList'
import ProductDelList from '@/components/Product/ProductDelList'
import RiskManager from '@/components/Product/RiskManager'

//用户管理
import UserList from '@/components/User/userList'
import AgentList from '@/components/User/AgentList'

//财务管理
import PayHand from '@/components/Recharge/PayHand'
import PayConfig from '@/components/Recharge/PayConfig'
import DrawList from '@/components/Recharge/DrawList'
import PayinList from '@/components/Recharge/PayinList'

import OrderList from '@/components/Order/orderList'
import ClosingOrder from '@/components/Order/ClosingOrder'

// 管理员管理
import WinOrLose from '@/components/report/WinOrLose'
import PayOrDraw from '@/components/report/PayOrDraw'
import FundReport from '@/components/report/FundReport'

// 管理员管理
import managerList from '@/components/Manager/managerList'
import addManager from '@/components/Manager/addManager'
import Service from '@/components/Manager/Service'

// 权限管理
import permissionList from '@/components/Permission/permissionList'
import roleList from '@/components/Permission/roleList'

import App from '@/App.vue'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // 处理错误 跳转到重复路径上，忽略错误
    // console.error(err);
  });
};

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'App',
      component: App,
	  children:[
		  {
			path:'',
			component: MainFrame,
		  },
		  {
			name: 'Home',
			path: 'Home',
			component: MainFrame,
			children:[
				{
					path:'/Dashboard',
					name:'Dashboard',
					component:Dashboard
				},{
					path:'/ProductList',
					name:'ProductList',
					component:ProductList
				},{
					path:'/ProductDelList',
					name:'ProductDelList',
					component:ProductDelList
				},{
					path:'/RiskManager',
					name:'RiskManager',
					component:RiskManager
				},
				{
					path:'/UserList',
					name:'UserList',
					component:UserList
				},{
					path:'/AgentList',
					name:'AgentList',
					component:AgentList
				},{
					path:'/PayHand',
					name:'PayHand',
					component:PayHand
				},{
					path:'/PayConfig',
					name:PayConfig,
					component:PayConfig
				},{
					path:'/DrawList',
					name:DrawList,
					component:DrawList
				},{
					path:'/PayinList',
					name:PayinList,
					component:PayinList
				},{
					path:'/OrderList',
					name:'OrderList',
					component:OrderList
				},{
					path:'/ClosingOrder',
					name:'ClosingOrder',
					component:ClosingOrder
				},{
					path:'/Permission/roleList',
					name:'roleList',
					component:roleList
				},{
					path:'/Permission/permissionList',
					name:'permissionList',
					component:permissionList
				},{
					path:'/Manager/managerList',
					name:'managerList',
					component:managerList
				},{
					path:'/Manager/addManager',
					name:'addManager',
					component:addManager
				},{
					path:'/Manager/Service',
					name:'Service',
					component:Service
				},{
					path:'/FundReport',
					name:'FundReport',
					component:FundReport
				},{
					path:'/PayOrDraw',
					name:'PayOrDraw',
					component:PayOrDraw
				},{
					path:'/WinOrLose',
					name:'WinOrLose',
					component:WinOrLose
				}
			]
		  }
	  ]
    },{
      path: '/Login',
      name: 'Login',
      component: Login
    }
  ]
})

<style>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 120px;
	    height: 120px;
	    line-height: 120px;
	    text-align: center;
	  }
	  .avatar {
	    width: 120px;
	    height: 120px;
	    display: block;
	  }
	  
	  .avatar-uploader-icon1 {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar1 {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>

		<div class="line-box">
			<div class="line-name">类型：</div>
			<el-select style="width: 720px;" v-model="status" placeholder="请选择类型">
			    <el-option v-for="item in digit_list" :key="item.value" :label="item.label" :value="item.value"></el-option>
			  </el-select>
		</div>
		
		<div class="line-box">
			<div class="line-name">原因：</div>
			<el-input style="width: 720px;" placeholder="操作意见" v-model="reason"></el-input>
		</div>
		
		
		<div style="width: 100%; margin-top: 20px;">
			<el-button style="width: 100%;" type="danger" @click="submit">确认</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props:['payinOrder'],
		data(){
			return {
				status:'',
				reason:'',
				digit_list:[
					{
						lable:'通过',
						value:'通过'
					},{
						lable:'拒绝',
						value:'拒绝'
					}
				]
			}
		},
		methods: {
			submit(){
				let _this = this;
				let _status = this.status == "通过" ? 1 : 2;
				const loading = this.$loading({
						lock: true,
						text: '编辑中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Recharge/editPayinOrder", {status:_this.status, reason:_this.reason, orid:_this.payinOrder.orid}).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.payinOrder.status = _status;
						_this.payinOrder.action_remark = _this.reason;
						_this.$message({
							 message: '操作成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
		},
	}
</script>

<style scoped>
	
	
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		margin-left: 30px;
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
		margin-left: 30px;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
		margin-bottom: 30px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div class="content">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="getData" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>产品管理</el-breadcrumb-item>
			  <el-breadcrumb-item>产品列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="background-color: white; margin: 10px; padding: 30px 0; border-radius: 10px;">
			<div class="line-box">
				<div class="line-name">指定客户亏损：</div>
				<el-input style="width: 720px;" v-model="form.lose_uid_str" placeholder="请填写客户ID" ></el-input>
			</div>
			<div class="line-tag-box">
				<div class="line-name"></div>
				<div class="line-tag">说明： 此处设置会员ID（如：8888），多个用户用|符号分开（如：8888|9999）设置之后该会员所有订单都会亏损，请谨慎操作。如停止该功能请在上面留空或者填0，并提交。</div>
			</div>
			
			<div class="line-box">
				<div class="line-name">指定客户赢利：</div>
				<el-input style="width: 720px;" v-model="form.win_uid_str" placeholder="请填写客户ID" ></el-input>
			</div>
			<div class="line-tag-box">
				<div class="line-name"></div>
				<div class="line-tag">说明： 此处设置会员ID（如：8888），多个用户用|符号分开（如：8888|9999）设置之后该会员所有订单都会赢利，请谨慎操作。如停止该功能请在上面留空或者填0，并提交。</div>
			</div>
			
			<div class="line-box">
				<div class="line-name">最小风控值：</div>
				<el-input type="number" style="width: 720px;" v-model="form.risk_value" placeholder="请填写最小风控值"></el-input>
			</div>
			<div class="line-tag-box">
				<div class="line-name"></div>
				<div class="line-tag">说明： 下单达到此金额，则会受到下面风控影响。</div>
			</div>
			
			
			<div class="line-box">
				<div class="line-name">风控概率：</div>
				<el-input style="width: 720px; " placeholder="" v-model="form.risk_rate_str"></el-input>
			</div>
			<div class="line-tag-box">
				<div class="line-name"></div>
				<div class="line-tag">说明： 输入金额区间，在金额区间之内会根据此概率盈亏。不在此区间则不受风控影响
	格式： 区间开始-区间结束:客户赢利概率|　　符号必须为英文符号，如0-100:50|100-200:30</div>
			</div>
			
			<div style="width: 100%; margin-top: 20px; margin-left: 30px;">
				<el-button style="width: 860px;" type="danger" @click="submit">提交保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				form:{
					lose_uid_str:'',
					win_uid_str:'',
					risk_value:'',
					risk_rate_str:''
				}
			}
		},
		created(){
			this.getData();
		},
		methods: {
			getData(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '加载中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.get("/Product/getRiskManager", {}).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.form = res.data;
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Product/setRiskManager", this.form).then(res=>{
					if(res.code == 200)
					{
						_this.$message({
							 message: '保存成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close();
				}).catch(err=>{
					loading.close();
				});
			},
		},
	}
</script>

<style scope>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>
		<div class="upload-box">
			<div class="line-name">上传头像：</div>
			<div style="display: block;">
				<el-upload class="avatar-uploader" :action="this.$http_url + '/Upload/UploadImage'" :headers="headers" :show-file-list="false" 
					:on-success="UploadSuccess"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="userInfo.avatar_fullurl" :src="userInfo.avatar_fullurl" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			
		</div>
		<div class="line-box">
			<div class="line-name">UID：</div>
			<el-input style="width: 720px;" placeholder="" disabled v-model="userInfo.uid"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">账号：</div>
			<el-input style="width: 720px;" placeholder="" v-model="userInfo.email"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">昵称：</div>
			<el-input style="width: 720px;" placeholder="" v-model="userInfo.nickname"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">密码：</div>
			<el-input style="width: 720px;" placeholder="重置密码" v-model="userInfo.password"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 重置修改密码的时候才填写，不修改留空</div>
		</div>
		<div class="line-box">
			<div class="line-name">余额：</div>
			<el-input style="width: 720px;"  v-model="userInfo.balance"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">注意： 余额请谨慎操作，不产生帐变记录，直接修改。</div>
		</div>
		<div class="line-box">
			<div class="line-name">邀请码：</div>
			<el-input style="width: 720px;" disabled v-model="userInfo.invite_code"></el-input>
		</div>
		<div class="line-box">
			<div class="line-name">备注：</div>
			<el-input style="width: 720px;"  v-model="userInfo.remark"></el-input>
		</div>
		
		<div style="width: 100%; margin-top: 20px;">
			<el-button style="width: 100%;" type="danger" @click="addProduct">确认修改</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props:['userInfo'],
		data(){
			return {
				editStatus : false
			}
		},
		data(){
			return {
				headers:{
					Authorization:''
				}
			}
		},
		created() {
			this.headers.Authorization = localStorage.getItem('access_token');
		},
		methods: {
			addProduct() {
				// 验证格式
				if(this.userInfo.avatar_url == ""){
					return this.$message({ message: '请上传头像', type: 'warning' });
				}
				if(this.userInfo.email == ""){
					return this.$message({ message: '请填写账号', type: 'warning' });
				}
				if(this.userInfo.nickname == ""){
					return this.$message({ message: '请填写昵称', type: 'warning' });
				}
				// 提交保存
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/User/editUser", this.userInfo).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '编辑成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(err=>{
					loading.close();
				});
			},
			beforeUpload(file){
			},
			UploadSuccess(res, file, fileList){
				if(res.code == 200)
				{
					this.userInfo.avatar_url = res.file_path;
					this.userInfo.avatar_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			}
		},
	}
</script>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center;">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>首页</el-breadcrumb-item>
				  <el-breadcrumb-item>数据看板</el-breadcrumb-item>
				</el-breadcrumb>
			
			</div>
			
		</div>
		<div class="row">
			<div class="data-box">
				<div class="data-icon cl_green"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.today_order}}</div>
					<div class="cvalue">今日订单</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_green"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.yesterday_order}}</div>
					<div class="cvalue">客户盈亏</div>
				</div>
			</div>
			
			
			<div class="data-box">
				<div class="data-icon cl_red"><i class="el-icon-user-solid"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.today_price}}</div>
					<div class="cvalue">今日流水</div>
				</div>
			</div>
			<div class="data-box">
				<div class="data-icon cl_red"><i class="el-icon-user-solid"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.yesterday_price}}</div>
					<div class="cvalue">今日充值</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_orange"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.month_order}}</div>
					<div class="cvalue">今日提现</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_orange"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.lastmonth_order}}</div>
					<div class="cvalue">当天手续费</div>
				</div>
			</div>
			
		</div>
		
		<div style="width: 100%;">
			<div style="margin: 20px; padding: 20px; border-radius: 10px; background-color: white;">
				<div style="font-size: 16px; color: #999999; margin-bottom: 20px;">最新交易记录</div>
				<el-table :data="tableData" border  style="width: 100%"
						v-loading="loading"
						element-loading-text="拼命加载中"
						element-loading-spinner="el-icon-loading"
						element-loading-background="rgba(0, 0, 0, 0.8)" >
						
						<el-table-column prop="orid" label="订单编号" width="100"  align="center">
						</el-table-column>
						
						<el-table-column prop="uid" label="用户UID" width="100"  align="center">
						</el-table-column>
						
						<el-table-column prop="add_time" label="订单时间" align="center" width="170">
							<template slot-scope="scope">
								{{ formatTimestamp(scope.row.add_time) }}
							</template>
						</el-table-column>
						
						<el-table-column prop="inst" label="产品信息" width="200"  align="center">
						</el-table-column>
						
						<el-table-column prop="status" label="状态" width="100"  align="center">
						</el-table-column>
						
						<el-table-column prop="direction" label="方向" width="100"  align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.direction=='up'" style="color:red;">买涨</span>
								<span v-if="scope.row.direction=='down'" style="color:green;">买跌</span>
							</template>
						</el-table-column>
						
						<el-table-column prop="time" label="时间/点数" align="center" width="180">
							<template slot-scope="scope">
								<span>{{scope.row.time}}S / {{scope.row.rate}}%</span>
							</template>
						</el-table-column>
						
						<el-table-column prop="cur_price" label="建仓点位" align="center">
						</el-table-column>
						
						<el-table-column prop="calc_price" label="平仓点位" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.profit>0" style="color:red;">{{scope.row.calc_price}}</span>
								<span v-if="scope.row.profit<0" style="color:green;">{{scope.row.calc_price}}</span>
							</template>
						</el-table-column>
						
						<el-table-column prop="amount" label="委托金额" align="center">
						</el-table-column>
						
						<el-table-column prop="profit" label="实际盈亏" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.profit>0" style="color:red;">{{scope.row.profit}}</span>
								<span v-if="scope.row.profit<0" style="color:green;">{{scope.row.profit}}</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getTabale" :total="total" style="margin-top: 20px;"></el-pagination>
				
			</div>
		</div>
	</div>
</template>

<script>
	
	
	
	export default {
		name:'Dashboard',
		data(){
			return {
				static:{
					today_order:0,
					today_price:"0.00",
					yesterday_order:0,
					yesterday_price:"0.00",
					month_order:0,
					month_price:"0.00",
					lastmonth_order:0,
					lastmonth_price:"0.00",
					user_total:0,
					user_add:0
				},
				tableData: [],
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
			}
		},
		mounted() {
			this.getData(1);
			this.$nextTick(() => {
			});
			
		},
		created() {
			this.getTabale();
		},
		 beforeDestroy() {
		  },
		methods:{
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
			getTabale(page){
				let that = this;
				that.loading = true;
				that.$api.post("/Order/OrderList", {page:page}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			refresh(){
				console.log("refresh click");
				this.getData();
				this.handleResize();
			},
			getData(){
				const loading = this.$loading({
						lock: true,
						text: '拼命刷新中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Dashboard/getDashBoard", {store_id:that.store_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.static = res.data.static;
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(res=>{
					loading.close();
				});
					
			}
		}
	}
</script>


<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.cl_green {
		color: #67C23A;
	}
	.cl_orange {
		color: #E6A23C;
	}
	.cl_red {
		color: #F56C6C;
	}
	.cl_blue {
		color: #409EFF;
	}
	.cl_grey {
		color: #909399;
	}
	
	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: 15px 0 0 0;
	}
	
	.data-box {
		display: flex;
		flex-direction: row;
		background-color: white;
		padding: 20px 20px;
		margin: 5px 5px;
		border-radius: 10px;
		justify-content: space-between;
		width: 13%;
		min-width: 190px;
		box-shadow: 1px 1px 3px #CCCCCC;
	}
	
	.data-icon {
		font-size: 40px;
		margin-right: 20px;
	}
	.data-line {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
	}
	.ckey {
		font-size: 24px;
		line-height: 40px;
	}
	.cvalue {
		font-size: 16px;
		line-height: 24px;
		color: #999999;
	}
</style>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>产品管理</el-breadcrumb-item>
			  <el-breadcrumb-item>产品回收站</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" class="demo-form-inline">
						
					<el-form-item label="" style="width: 120px;">
						<el-select @change="statusChange" v-model="status" placeholder="所有订单" clearable>
							<el-option label="所有产品" value=""></el-option>
							<el-option label="开市中" value="1"></el-option>
							<el-option label="休市中" value="0"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="名称"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="sorts" label="排序" width="80"  align="center">
					<template slot-scope="scope">
						<el-input v-model="scope.row.sorts" @blur="SortsEvent(scope)" :style="{textAlign:'center'}"></el-input>
					</template>
				</el-table-column>
				
				<el-table-column prop="pr_id" label="编号" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="product_name" label="产品名称"  align="center">
				</el-table-column>
				
				<el-table-column prop="inst" label="产品代码"  align="center">
				</el-table-column>
				
				<el-table-column prop="icon_fullurl" label="图标"   align="center">
					<template slot-scope="scope">
						<img :src="scope.row.icon_fullurl" style="width: 50px; height: 50px;" />
					</template>
				</el-table-column>
				
				<el-table-column prop="rand_price" label="随机值" align="center">
				</el-table-column>
				
				<el-table-column prop="risk_low" label="风控最小值" align="center" >
				</el-table-column>
				
				<el-table-column prop="risk_high" label="风控最大值" align="center" >
				</el-table-column>
				
				
				<el-table-column prop="status" label="状态" align="center" >
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" type="info" @click="changeStatus(scope)" circle>休</el-button>
						<el-button v-if="scope.row.status!=0" type="warning" @click="changeStatus(scope)" circle>进</el-button>
					</template>
				</el-table-column>
				
				
				<el-table-column fixed="right" label="操作"  align="center">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="editProduct(scope)">编辑</el-button>
						<el-button type="danger" size="mini" @click="deleteProduct(scope)">恢复</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="添加产品" :visible.sync="addView" width="1000px" @close="addView=false">
			<addProduct></addProduct>
		</el-dialog>
		
		<el-dialog :title="editTitle" :visible.sync="editView" width="1000px" @close="editView=false">
			<editProduct :productInfo="productInfo"></editProduct>
		</el-dialog>
		
	</div>
</template>

<script>
	import editProduct from './editProduct.vue';
	
	export default {
		name:'List',
		components:{
			editProduct
		},
		data(){
			return {
				addView:false,
				/* 编辑参数 */
				editView:false,
				editTitle:'订单操作',
				productInfo:{},
				/* 搜索参数 */
				status:'',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			changeStatus(scope){
				let _this = this;
				let _status = scope.row.status == 1 ? 0 : 1;
				_this.$api.post("/Product/Status", {pr_id:scope.row.pr_id, status:_status}).then(res=>{
					if(res.code == 200)
					{
						_this.tableData[scope.$index].status = _status;
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			SortsEvent(scope){
				let _this = this;
				_this.$api.post("/Product/Sorts", {pr_id:scope.row.pr_id, sorts:scope.row.sorts}).then(res=>{
					if(res.code == 200)
					{
						_this.getData();
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			deleteProduct(scope){
				let that = this;
				this.$confirm('是否确认恢复此产品?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.deleteProductAction(scope);
				}).catch(() => {
				});	
			},
			deleteProductAction(scope){
				const loading = this.$loading({
						lock: true,
						text: '恢复中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Product/renewProduct", {pr_id:scope.row.pr_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 200)
					{
						that.tableData.splice(scope.$index, 1);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			editProduct(scope){
				this.productInfo = scope.row;
				this.editTitle = '编辑 - ' + scope.row.product_name;
				this.editView = true;
			},
			statusChange(e){
				this.status = e;
				this.getData();
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Product/getProductDelList", {page:page, keyword:that.keyword, status: that.status}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			}
		}
	}
</script>
<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>财务管理</el-breadcrumb-item>
			  <el-breadcrumb-item>提现列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" class="demo-form-inline">
						
					<el-form-item label="" style="width: 120px;">
						<el-select @change="statusChange" v-model="status" placeholder="所有状态" clearable>
							<el-option label="所有状态" value="99"></el-option>
							<el-option label="待审核" value="0"></el-option>
							<el-option label="已通过" value="1"></el-option>
							<el-option label="已拒绝" value="2"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="UID / 订单编号"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="orid" label="编号" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="uid" label="UID"  align="center">
				</el-table-column>
				
				<el-table-column prop="amount" label="金额" align="center">
				</el-table-column>
				
				<el-table-column prop="payway" label="提现信息"  align="center" width="300">
					<template slot-scope="scope">
						<div v-if="scope.row.binding.bank_type == 'bank'">
							<div>银行名称：{{scope.row.binding.bankname}}</div>
							<div>银行卡号：{{scope.row.binding.card_number}}</div>
							<div>持卡姓名：{{scope.row.binding.card_holder}}</div>
							<div>卡片类型：{{scope.row.binding.card_type}}</div>
							<div>联系方式：{{scope.row.binding.contact_info}}</div>
						</div>
						<div v-if="scope.row.binding.bank_type == 'digit'">
							<div>币种：{{scope.row.binding.digit_type}}</div>
							<div v-if="scope.row.binding.digit_type == 'USDT'">链名：{{scope.row.binding.chain_type}}</div>
							<div>地址：{{scope.row.binding.draw_addr}}</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="add_time" label="提交时间"  align="center">
					<template slot-scope="scope">
						{{ formatTimestamp(scope.row.add_time) }}
					</template>
				</el-table-column>
				
				<el-table-column prop="action_remark" label="备注" align="center" >
				</el-table-column>
				
				<el-table-column prop="update_time" label="审核时间" align="center" >
					<template slot-scope="scope">
						{{ formatTimestamp(scope.row.update_time) }}
					</template>
				</el-table-column>
				
				
				<el-table-column prop="status" label="状态" align="center" >
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" size="mini" type="info" @click="changeStatus(scope)" >审核中</el-button>
						<el-button v-if="scope.row.status==1" size="mini" type="primary" @click="changeStatus(scope)" >已通过</el-button>
						<el-button v-if="scope.row.status==2" size="mini" type="danger" @click="changeStatus(scope)" >已拒绝</el-button>
					</template>
				</el-table-column>
				
				
				<el-table-column fixed="right" label="操作"  align="center">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" type="danger" size="mini" @click="passAction(scope)">操作</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="操作" :visible.sync="addView" width="800px" @close="addView=false">
			<passDrawAction :payinOrder="payinOrder"></passDrawAction>
		</el-dialog>
		
	</div>
</template>

<script>
	
	import passDrawAction from './passDrawAction.vue';
	
	export default {
		name:'List',
		components:{
			passDrawAction
		},
		data(){
			return {
				payinOrder:{},
				addView:false,
				/* 搜索参数 */
				status:'99',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			passAction(scope){
				this.addView = true;
				this.payinOrder = scope.row;
				console.log(scope);
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Recharge/getDrawList", {page:page, keyword:that.keyword, status: that.status}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			},
			statusChange(e){
				this.status = e;
				this.getData();
			},
		}
	}
</script>
<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>系统设置</el-breadcrumb-item>
			  <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" :model="searchForm" class="demo-form-inline">
					<el-form-item label="" style="width: 120px;">
						<el-select v-model="searchForm.status" placeholder="所有人" clearable>
							<el-option label="所有人" value="99"></el-option>
							<el-option label="正常" value="1"></el-option>
							<el-option label="禁用" value="0"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="searchForm.keyword" placeholder="aid、账号、昵称"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="aid" label="编号" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="nickname" label="昵称" align="center">
				</el-table-column>
				
				<el-table-column prop="username" label="账号" align="center">
				</el-table-column>
	
				
				<el-table-column prop="last_login_time" label="最近登录" align="center">
				</el-table-column>
				
				<el-table-column prop="add_time" label="注册时间" align="center" >
				</el-table-column>
				
				
				<el-table-column prop="status" label="状态" align="center" >
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" @click="changeStatus(scope)" circle>禁</el-button>
						<el-button v-if="scope.row.status!=0" @click="changeStatus(scope)" type="primary" circle>正</el-button>
					</template>
				</el-table-column>
				
				
				<el-table-column fixed="right" label="操作" width="220" align="center">
					<template slot-scope="scope">
						<el-button @click="EditUser(scope.row)" type="primary" size="mini">编辑查看</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="编辑查看" :visible.sync="editView" width="1000px" @close="editView=false">
			<editManager :userInfo="userInfo"></editManager>
		</el-dialog>
		
	</div>
</template>

<script>
	import editManager from './editManager.vue';
	export default {
		components:{
			editManager
		},
		name:'List',
		data(){
			return {
				balance_uid:0,
				userInfo:{},
				balanceView:false,
				editView:false,
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				searchForm:{
					status:"99",
					keyword:""
				},
				tableData: []
			}
		},
		created() {
			this.getData(1);
		},
		methods:{
			
			changeStatus(scope){
				let _this = this;
				let user = scope.row;
				let _status = user.status == 0 ? 1 : 0;
				_this.$api.post("/User/editStatus", {uid:user.uid, status:_status}).then(res=>{
					if(res.code == 200){
						_this.tableData[scope.$index].status = _status;
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			EditUser(row){
				this.userInfo = row;
				this.userInfo.password = '';
				this.editView = true;
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
			refresh(){
				this.getData(1);
				this.tableKey +=1;
			},
			getData(page){
				let that = this;
				that.loading = true;
				that.$api.post("/Admin/AdminList", {page:page, status:that.searchForm.status, keyword:that.searchForm.keyword}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleClick(e){
			},
			onSubmit(){
				this.getData(1);
			}
		}
	}
</script>